var _ = require("lodash");
var Utils = require("./utils");
var queryArray = Utils.queryArray;

var previousScrollPosition = 0;
var header = document.querySelector("header");
var form = document.querySelector("form.form");
var formBtns = queryArray(".btn--form");

formBtns.forEach(function(btn) {
  btn.addEventListener("click", function() {
    form.classList.add("changed");
    setTimeout(function() {
      form.classList.remove("changed");
    }, 2000);
  });
});

function updateHeaderPosition() {
  // if past the header
  var pageOffset = window.scrollY || window.pageYOffset;

  if (pageOffset > 100) {
    // check if difference is negative
    if (
      previousScrollPosition > pageOffset && form &&
      form.classList.contains("changed")
    ) {
      header.classList.remove("scroll-hide");
    } else {
      var siteHeader = document.querySelector(".site-header");
      var mobileNav = document.querySelector(".mobile-navigation");
      var mobileNavPopup = document.querySelector(".mobile-navigation-popup");

      header.classList.add("scroll-hide");
      if (siteHeader) siteHeader.classList.remove("mobile-nav-active");
      if (mobileNav) mobileNav.classList.remove("popup-active");
      if (mobileNavPopup) mobileNavPopup.classList.remove("popup-active");
    }
  } else {
    header.classList.remove("scroll-hide");
  }
  previousScrollPosition = pageOffset;
}

window.addEventListener(
  "scroll",
  _.debounce(updateHeaderPosition, 5, { trailing: false, leading: true })
);